<template>
  <div class="friendsCircle_explain_page">
    <div class="header_common_box">
      <v-header :params="{ title, leftIcon: true }"></v-header>
    </div>

    <van-collapse v-model="activeName" accordion>
      <van-collapse-item v-for="(item, index) in list" :key="index" :title="item.title" :name="index + 1">
        <div v-html="item.content"></div>
      </van-collapse-item>
    </van-collapse>
  </div>
</template>

<script>
import vHeader from "@/components/header.vue";
export default{
    components: {
    vHeader
  },
  data(){
    return{
        title: this.$route.meta.title,
        activeName: '1',
        list: [
            {title:'1.什么是达人，有何好处？',content:'<p>达人，一定是位有着多年研究经验的高手、品德优秀、待人谦虚低调、愿与彩民深度分享与交流</p><br><p style="color:red;">达人的好处:</p><p>1、收到的<span style="color:red;">打赏</span>5 : 5 分成</p><p>2、免费赠送年费VIP4</p><p>3、达人的辛苦抓规，可以设置付费可看</p>'},
            {title:'2.什么是大师，有何好处？',content:'<p>大师，有很多种，有头尾大师、芝麻大师等25种大师，只要每期发表预测帖，并且满足大师的要求，自动会成为大师（若成为了大师，平台会发送系统消息给您），关于成为大师的要求，请看下面的->如何成为大师</p><br><p style="color:red;">大师的好处:</p><p>1、心水预测可收费（大致与平台5 : 5分成）</p><p>2、免费开通打赏功能（打赏5 : 5）</p>'},
            {title:'3.什么是周月榜，有何好处？',content:'<p>周月榜，平台每周，每月都会对上周、上月彩友们发布的预测进行统计，评出最优的乘积入选周月榜</p><br><p style="color:red;">入选周月榜的好处:</p><p>1、获得周月榜奖励，<a href="http://m.haojingchou.cn/#/ranking/award" style="color:blue;">点击查看奖励明细</a></p><p>2、免费开通打赏功能（打赏5 : 5）分成</p>'},
            {title:'4.如何成为达人？',content:'<p>达人需要具备的条件太多，如：实力、受欢迎度、素质、品德、态度等等，平台定期会从彩友圈里面发现符合达人条件的彩友，并会主动联系，若有成为达人的彩友，请一定要完善自己的联系方式，如微信、QQ（完善信息入口：我的 -> 设置 -> 个人设置）</p>'},
            {title:'5.如何成为大师？',content:'<p>满足大师的标准，将自动成为大师，平台会发系统消息告诉您，<a href="http://m.haojingchou.cn/#/ranking/masterstandard" style="color:blue;">大师标准【点击必读】</a></p>'},
            {title:'6.如何入选周榜？',content:'<p>每周一都会对上一周，有发表超过2帖预测的用户进行统计，统计按回报率高低排序，回报率前20名将入选周榜</p>'},
            {title:'7.如何入选月榜？',content:'<p>每月1号都会对上个月，有发表超过10帖预测的用户进行统计，统计按回报率高低排序，回报率前10名将入选周榜</p>'},
            {title:'8.什么是回报率？',content:'<p>回报率是最基础，也是非常重要的指标，它能反映出彩友预测的水平，以及长期来看，是否稳定。若想了解回报率，<a href="localhost/home" style="color:blue;">【点击必读】</a></p>'},
        ],
    }
  }
}



</script>

<style>

</style>